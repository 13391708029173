import { useLazyQuery } from '@apollo/client';
import { MigrateStatus, type MigrateResult } from '@u-next/unextjs-oauth';
import { atom, useAtom } from 'jotai';
import React from 'react';
import { graphql } from '../gql/__codegen__';
import { useFetch } from './useFetch';
import type { ServiceControlCodeValues } from '../constants/serviceControlCode';

const QUERY = graphql(`
  query shared_userInfoAndMenus($deviceCode: Int!) {
    userInfo {
      id
      multiAccountId
      userPlatformId
      userPlatformCode
      superUser
      otherFunctionId
      accountTypeCode
    }
    getSettingMenu(deviceCode: $deviceCode) {
      menu {
        serviceControlCode
        serviceControlName
      }
    }
  }
`);

export type UserInfo = {
  isLoggedIn: boolean;
  userPlatformCode: string;
  userPlatformId: string;
  userMultiAccountId: string;
  otherFunctionId: string;
  accountTypeCode: string;
  isSuperUser: boolean;
  isMainAccount: boolean;
  isUnextPF: boolean;
  isYamadaPF: boolean;
  isBosPF: boolean;
  isWith: boolean;
  isAeon: boolean;
  isBBM: boolean;
  isTSUTAYA: boolean;
  isParavi: boolean;
  isAuPack: boolean;
};

type SettingMenu = {
  serviceControlCode: string;
  serviceControlName: string;
};

type UserInfoAndMenusAtom = {
  isLoaded: boolean;
  userInfo: UserInfo;
  settingMenu: SettingMenu[];
};

const userInfoAndMenusAtom = atom<UserInfoAndMenusAtom>({
  isLoaded: false,
  userInfo: {
    isLoggedIn: false,
    userPlatformCode: '',
    userPlatformId: '',
    userMultiAccountId: '',
    otherFunctionId: '',
    accountTypeCode: '',
    isSuperUser: false,
    isMainAccount: false,
    isUnextPF: false,
    isYamadaPF: false,
    isBosPF: false,
    isWith: false,
    isAeon: false,
    isBBM: false,
    isTSUTAYA: false,
    isParavi: false,
    isAuPack: false,
  },
  settingMenu: [],
});

type UseUserInfoAndMenus = {
  isLoadedUserInfo: boolean;
  userInfo: UserInfo;
  fetchUserInfo: () => Promise<void>;
  isExistsMenu: (serviceControlCode: ServiceControlCodeValues) => boolean;
};

type MigrationResponse = {
  result: MigrateResult;
};

let initialized = false;
export const useUserInfoAndMenus = (): UseUserInfoAndMenus => {
  const { fetcher: migrateTokensFetcher } = useFetch<MigrationResponse>();
  const [getUserInfoFetcher] = useLazyQuery(QUERY);
  const [userInfoAndMenus, setUserInfoAndMenus] = useAtom(userInfoAndMenusAtom);

  const fetchUserInfo: UseUserInfoAndMenus['fetchUserInfo'] =
    React.useCallback(async () => {
      let apiData = await getUserInfoFetcher({
        variables: { deviceCode: 700 },
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      });

      const forceMigration =
        !apiData.data?.userInfo?.id && !apiData.error?.networkError;

      const migrationResponse = await migrateTokensFetcher(
        '/api/migrateTokens',
        {
          method: 'POST',
          cache: 'no-cache',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            forceMigration,
          }),
        }
      );

      if (migrationResponse?.result.status === MigrateStatus.SUCCESS) {
        apiData = await getUserInfoFetcher({
          variables: { deviceCode: 700 },
          fetchPolicy: 'no-cache',
        });
      }

      const userPlatformCode = apiData.data?.userInfo?.userPlatformCode ?? '';
      const userPlatformId = apiData.data?.userInfo?.userPlatformId ?? '';
      const otherFunctionId = apiData.data?.userInfo?.otherFunctionId ?? '';
      const accountTypeCode = apiData.data?.userInfo?.accountTypeCode ?? '';

      setUserInfoAndMenus({
        isLoaded: true,
        userInfo: {
          isLoggedIn: userPlatformId !== '',
          userPlatformCode,
          userPlatformId,
          userMultiAccountId: apiData.data?.userInfo?.multiAccountId ?? '',
          otherFunctionId,
          accountTypeCode,
          isSuperUser: apiData.data?.userInfo?.superUser ?? false,
          isMainAccount: accountTypeCode === 'MAIN',
          isUnextPF: userPlatformCode === 'unext',
          isYamadaPF: userPlatformCode === 'yamada',
          isBosPF: userPlatformCode === 'bos',
          isWith:
            Number(otherFunctionId) >= 500 && Number(otherFunctionId) <= 599,
          isAeon: otherFunctionId === '600',
          isBBM: otherFunctionId === 'bbm',
          isTSUTAYA: otherFunctionId === 'tsutaya',
          isParavi: otherFunctionId === '201',
          isAuPack: otherFunctionId === '202',
        },
        settingMenu:
          apiData.data?.getSettingMenu.menu.map((data) => ({
            serviceControlCode: data.serviceControlCode ?? '',
            serviceControlName: data.serviceControlName ?? '',
          })) ?? [],
      });
    }, [getUserInfoFetcher, migrateTokensFetcher, setUserInfoAndMenus]);

  const isExistsMenu: UseUserInfoAndMenus['isExistsMenu'] = React.useCallback(
    (serviceControlCode) =>
      !!userInfoAndMenus.settingMenu.find(
        (data) => data.serviceControlCode === serviceControlCode
      ),
    [userInfoAndMenus.settingMenu]
  );

  React.useEffect(() => {
    if (initialized) {
      return;
    }

    initialized = true;

    fetchUserInfo();
  }, [fetchUserInfo]);

  return {
    isLoadedUserInfo: userInfoAndMenus.isLoaded,
    userInfo: userInfoAndMenus.userInfo,
    fetchUserInfo,
    isExistsMenu,
  };
};
