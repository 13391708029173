import Head from 'next/head';

export const GlobalHead: React.FC = () => (
  <Head>
    <meta
      key="viewport"
      name="viewport"
      content="width=device-width, user-scalable=no, initial-scale=1.0"
    />
    <meta key="X-UA-Compatible" httpEquiv="X-UA-Compatible" content="ie=edge" />
    {/*
    we can not use svg favicon by external.
    https://u-next.slack.com/archives/C5K6XKCBY/p1670219612458029
    https://stackoverflow.com/questions/66935329/use-inline-svg-as-favicon
    */}
    <link
      rel="icon"
      type="image/svg+xml"
      href="data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E %23i %7B fill: %23232D3A; %7D @media (prefers-color-scheme: dark) %7B %23i %7B fill: %23fff; %7D %7D %3C/style%3E%3Cpath id='i' fill-rule='evenodd' clip-rule='evenodd' d='M51.9704 36.9223C51.9704 47.8086 43.1452 56.634 32.2587 56.634C21.3721 56.634 12.5469 47.8086 12.5469 36.9223V13.5741C12.5469 12.553 13.3229 11.7133 14.3174 11.6129L49.799 8.06472C49.8649 8.05789 49.9319 8.05472 49.9995 8.05472C51.0881 8.05472 51.9706 8.93707 51.9706 10.0259L51.9704 36.9223ZM50.9847 3.04761C50.7862 3.04761 50.59 3.05809 50.3964 3.0776L12.9443 6.82251C9.95471 7.11801 7.61902 9.63924 7.61902 12.7064V36.9223C7.61902 50.5302 18.6507 61.5619 32.2587 61.5619C45.8669 61.5619 56.8983 50.5302 56.8983 36.9223V8.96096C56.8983 5.69514 54.2508 3.04761 50.9847 3.04761ZM42.6074 15.4465C42.5401 15.4465 42.474 15.4497 42.4087 15.4563L21.7163 17.5255C20.7184 17.6228 19.9387 18.4639 19.9387 19.4872V36.9223C19.9387 43.7263 25.4547 49.242 32.2587 49.242C39.0629 49.242 44.5786 43.7263 44.5786 36.9223V17.4175C44.5786 16.3289 43.696 15.4465 42.6074 15.4465Z' /%3E%3C/svg%3E%0A"
    />
    <link
      rel="icon alternate"
      sizes="64x64"
      type="image/png"
      href="/images/icon/favicon.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/images/icon/apple-touch-icon.png"
    />
    <link rel="icon" sizes="192x192" href="/images/icon/android-icon.png" />
    <meta
      key="ogImage"
      property="og:image"
      content="https://metac.nxtv.jp/img/static_resource/img/wf/stargate/ogp.png"
    />
    <title key="title">U-NEXT</title>
  </Head>
);
