/** How to use:
 *
 *  In root component:
 *
 *  import { TapiContext} from 'shared/hooks/useTapiFetcher';
 *  ...
 *  <TapiContext.Provider value={GlobalConfig.TAPI_ENDPOINT}>
 *    {children}
 *  </TapiContext.Provider>
 */

import React, { useState, useCallback, useContext } from 'react';
import type { TapiResponse } from 'u-next/api';

const CLIENT_ERROR_RESPONSE: TapiResponse = {
  common: {
    userInfo: {
      userToken: '',
      securityToken: '',
    },
    result: {
      errorCode: 'FRONT_ERROR',
      errorMessage:
        'サーバへのアクセスが混み合っているか、メンテナンス中の可能性があります。',
    },
  },
  data: {},
};

export const TapiContext = React.createContext<string | null>(null);

export const useTapiFetcher = <T extends TapiResponse>(): {
  fetcher: (path: string, reqData: Record<string, unknown>) => Promise<T>;
  loading: boolean;
  data: T | null;
} => {
  const endpoint = useContext(TapiContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<T | null>(null);

  const tapiFetcher = useCallback(
    (path: string, reqData: Record<string, unknown>): Promise<T> => {
      // If TapiContext is not correctly set, the site cannot function properly
      if (!endpoint) {
        throw 'Tapi endpoint is not available';
      }

      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options: RequestInit = {
        method: 'POST',
        credentials: 'include',
        cache: 'no-cache',
        headers,
        body: JSON.stringify({
          common: {
            deviceInfo: {
              appVersion: '1',
              deviceType: '700',
            },
          },
          data: reqData,
        }),
      };

      setLoading(true);

      return fetch(`${endpoint}${path}`, options)
        .then((response: Response): Promise<T> => {
          if (!response.ok) {
            let errorMessage = `${response.status} ${response.statusText}`;
            if (response.status === 401) {
              errorMessage = 'アクセス方法が正しくありません。';
            }
            return Promise.reject(new Error(errorMessage));
          }

          return response.json().then((json): T => {
            setData(json);
            return json;
          });
        })
        .catch((error: Error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          return Promise.reject(CLIENT_ERROR_RESPONSE);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [endpoint]
  );

  return {
    fetcher: tapiFetcher,
    loading,
    data,
  };
};
