import {
  ApolloClient,
  defaultDataIdFromObject,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ApolloLinks } from '@u-next/unextjs-oauth';
import { GlobalConfig } from '@/configs/globalConfig';
import type { NormalizedCacheObject, StoreObject } from '@apollo/client';
import type { ITokenHolder } from '@u-next/unextjs-oauth';

class TokenHolder implements ITokenHolder {
  private tokenValue: string | undefined;
  set(newToken: string): void {
    this.tokenValue = newToken;
  }
  get(): string | undefined {
    return this.tokenValue;
  }
}

export const initApollo = (): ApolloClient<NormalizedCacheObject> => {
  const isBrowser = typeof window !== 'undefined';
  const apiEndpoint = GlobalConfig.COMMAND_CENTER;
  const accessTokenHolder = new TokenHolder();

  return new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser,
    name: 'stargate_help',
    version: process.env.COMMIT_TAG,
    link: from([
      ApolloLinks.createRetryLink({
        delay: {
          initial: 300,
          max: Infinity,
          jitter: true,
        },
        attempts: {
          max: 5,
          retryIf: (error, _operation) => {
            if (
              !!error &&
              !_operation.query.definitions.some(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (node: any) =>
                  node.kind === 'OperationDefinition' &&
                  node.operation === 'mutation'
              )
            ) {
              return true;
            }
            return false;
          },
        },
      }),
      onError(
        ApolloLinks.createErrorLinkWithOAuthRefresh(
          isBrowser,
          accessTokenHolder
        )
      ),
      ApolloLinks.createAuthLink(isBrowser, accessTokenHolder),
      new HttpLink({ uri: apiEndpoint, headers: {} }),
    ]),
    cache: new InMemoryCache({
      dataIdFromObject: (o: Readonly<StoreObject>) =>
        defaultDataIdFromObject(o),
    }),
  });
};
