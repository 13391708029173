import 'tailwind_config/styles/globals.css';
import 'tailwind_config/styles/custom.css';
import { ApolloProvider } from '@apollo/client';
import { Provider as JotaiProvider } from 'jotai';
import React from 'react';
import { GlobalConfig } from '@/configs/globalConfig';
import { initApollo } from '@/localShared/libs/graphql/initApollo';
import { GlobalHead } from '@/shared/components/functional/GlobalHead';
import { GlobalScripts } from '@/shared/components/functional/GlobalScripts';
import { TapiContext } from '@/shared/hooks/useTapiFetcher';
import type { AppProps, AppInitialProps, AppContext } from 'next/app';

const apolloClient = initApollo();

const CoreApp = ({ Component, pageProps }: AppProps) => (
  <ApolloProvider client={apolloClient}>
    <TapiContext.Provider value={GlobalConfig.TAPI_ENDPOINT}>
      <JotaiProvider>
        <GlobalHead />
        <Component {...pageProps} />
        <GlobalScripts {...GlobalConfig} />
        <div id="modal" />
      </JotaiProvider>
    </TapiContext.Provider>
  </ApolloProvider>
);

CoreApp.getInitialProps = async ({
  Component,
  ctx,
}: AppContext): Promise<AppInitialProps> => {
  const pageProps = Component.getInitialProps
    ? await Component.getInitialProps(ctx)
    : {};

  return {
    pageProps,
  };
};

export default CoreApp;
